






































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { meetingsStore, teamsStore } from '@/store'
import { copyToClipboard } from 'quasar'

interface Item {
  title: string,
  action: () => void
}

@Component({
  components: {
    IconMore: () => import('@tada/icons/dist/IconMore.vue'),
  },
})
export default class MeetingCardActions extends Vue {
  @Prop({
    required: true,
    type: Meeting,
  }) meeting!: Meeting

  private get items (): Item[] {
    const items = []
    items.push(this.copyLinkItem)
    if (this.meeting.canEdit) items.push(this.editItem)
    if (this.meeting.canDelete) items.push(this.removeItem)
    return items
  }

  private get removeItem (): Item {
    return {
      title: this.$t('meetings.rightBar.list.card.actions.delete').toString(),
      action: async () => {
        const isFreq = !!this.meeting.freq
        window.goal('Calendar', { Calendar: 'Удалить — из карточки в правой колонке' })
        if (isFreq) {
          const title = this.$t('meetings.removeDialog.titleRepeatable', {
            name: this.meeting.displayName,
          }).toString()
          const okCaption =
            this.$t('meetings.removeDialog.okCaption').toString()
          const cancelCaption =
            this.$t('meetings.removeDialog.cancelCaption').toString()
          const description =
            this.$t('meetings.removeDialog.descriptionRepeatable').toString()

          this.$q.dialog({
            component: () => import('../../components/ConfirmDialog.vue'),
            parent: this,
            okCaption,
            title,
            cancelCaption,
            description,
          }).onOk(async () => {
            await meetingsStore.actions.removeMeeting(this.meeting.jid)
          })
        } else {
          this.$q.dialog({
            component: () => import('@/components/Meetings/components/RemoveDialog.vue'),
            parent: this,
            meeting: this.meeting,
          }).onOk(async () => {
            await meetingsStore.actions.removeMeeting(this.meeting.jid)
          })
        }
      },
    }
  }

  private get copyLinkItem (): Item {
    return {
      title: this.$t('meetings.meeting.actions.link').toString(),
      action: () => {
        copyToClipboard(this.link)
        window.goal('Calendar', { Calendar: 'Скопировать ссылку — из карточки в правой колонке' })
      },
    }
  }

  private get link () {
    return `${location.origin}/${teamsStore.getters.currentTeam.uid}/chats/${this.meeting.jid}`
  }

  private get editItem (): Item {
    return {
      title: this.$t('meetings.rightBar.list.card.actions.edit').toString(),
      action: () => {
        meetingsStore.actions.openMeetingDialog({ meeting: this.meeting })
        window.goal('Calendar', { Calendar: 'Редактировать — из карточки в правой колонке' })
      },
    }
  }
}
